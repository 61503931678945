@font-face {
    font-family: "SukhumvitSet-Bold";
    src: url(../fonts/SukhumvitSet-Bold.ttf) format("opentype");
  }
  
  @font-face {
    font-family: "SukhumvitSet-SemiBold";
    src: url(../fonts/SukhumvitSet-SemiBold.ttf) format("opentype");
  }
  
  @font-face {
    font-family: "SukhumvitSet-Medium";
    src: url(../fonts/SukhumvitSet-Medium.ttf) format("opentype");
  }
  
  @font-face {
    font-family: "SukhumvitSet-Thin";
    src: url(../fonts/SukhumvitSet-Thin.ttf) format("opentype");
  }
  
  @font-face {
    font-family: "SukhumvitSet-Light";
    src: url(../fonts/SukhumvitSet-Light.ttf) format("opentype");
  }

body {
    background-color: #2e2e40;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.body-container-monitor {
    height: 90vh;
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitor-logo {
    position: fixed;
    height: 5vh;
    right: 0;
    top: 0;
}

.left-container {
    width: 25%;
    height: 100%;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
}

.right-container {
    width: 75%;
    height: 100%;
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
}

.date-time-container {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.date-container {
    font-size: 1.35vw;
    color: #eeeeee;
    font-family: "SukhumvitSet-SemiBold";
}

.time-container {
    font-size: 4.5vw;
    color: #eeeeee;
    font-family: "SukhumvitSet-Thin";
    font-weight: bolder;
    margin-top: -10%
}

.device-list-container {
    height: 80%;
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 10px;
    background-image: url("../image/monitor/gradient-bg.png");
    background-size:cover;
    border: 0.66px solid #ffffff;
    background-position: center;
    display: flex;
}

.device-list {
    width: 100%;
    margin: 5% 0%;
    display: flex;
}

.device-list > div {
    height: 100%;
    width: 100%;
    text-align: center;
}

.device-list > div:not(:first-child) {
    border-left: 0.66px solid #ffffff;
    width:55%;
}

.device-list-header {
    margin-top: -1vh;
    margin-bottom: 0.5rem;
    color: #eeeeee;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 1.25vw;
}

.device-info-container {
    width: 100%;
    height: 12.15%;
    display: flex;
    align-items: center;
}

.device-info-container > img {
    width: 30%; 
    height: auto;
    margin: 0 5% 0 10%;
}

.device-info-container > div {
    width: 100%;
    color: #eeeeee;
    font-family: "SukhumvitSet-Bold";
    font-size: 0.85vw;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 5.5vw;
}

.device-status-container {
    width: 100%;
    height: 12.15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "SukhumvitSet-Bold";
    font-size: 1vw;
}

.status-on {
    color: #49db4d !important;
}

.status-off {
    color: #c40000 !important;
}

.iaq-container {
    height: 25%;
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.iaq-card {
    height: 100%;
    width: calc(20% - 0.75rem);
    border-radius: 6px;
    background-image: url("../image/monitor/gradient-bg.png");
    border: 0.66px solid #ffffff;
    background-position: center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.iaq-card > p {
    font-family: "SukhumvitSet-Bold";
    color: #eeeeee;
    font-size: 0.9vw;
    margin-top: 0.75rem;
}

.circular-container {
    width: 65%;
}

.circular-value-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eeeeee;
    font-family: "SukhumvitSet-Thin";
    font-size: 2.5vw;
    position: relative;
}

.circular-status {
    position: absolute;
    font-size: 0.7vw;
    top: -20%;
    font-family: "SukhumvitSet-Light";
    width: max-content;
}

.circular-icon {
    position: absolute;
    bottom: 5%;
    height: 1.75vw;
    width: auto;
}

 
.plan-container {
    margin-top: 2.5%;
    height: 72.5%;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan-title {
    color: #eeeeee;
    font-family: "SukhumvitSet-Medium";
    font-size: 1.95vw;
    margin: 0;
    text-align: center;
}

.plan-image-container {
    height: 90%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}